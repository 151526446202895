// [216777] Automated Email Order Confirmation
export default Object.freeze([
  {
    id: 'ft5gmqle2',
    spacing: {
      margin: '0',
      padding: '0',
    },
    verticalAlignment: 'MIDDLE',
    heroEffect: {},
    columns: [
      {
        id: 'm6l168r9f',
        background: {
          color: '#FFF',
        },
        attributes: {
          className: 'Grid_align-self-center',
        },
        padding: '26px 26px 0',
        contentBlocks: [
          {
            id: '9n7iuyxbf',
            name: 'Logo',
            spacing: {
              padding: '0 0 14px',
            },
          },
          {
            id: 'lsh9735px',
            name: 'SanaText',
            model: {
              key: 'ChangePassword',
              styleMode: 'Heading1',
            },
            spacing: {
              margin: '9px 0',
            },
          },
          {
            id: 'hguh2643y',
            name: 'SanaText',
            model: {
              key: 'ChangePassword_Description',
              styleMode: 'None',
            },
          },
          {
            id: 'c7uqmjgek',
            name: 'ResetPasswordForm',
            spacing: {
              margin: '40px 0 20px',
            },
          },
        ],
      },
    ],
  },
]);
