import styles from '../ForgotPassword.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaForm, FormGroup, CaptchaFormGroup, EmailAddressField, useCaptchaOnSubmit } from 'components/objects/forms';
import { submitForgotPassword } from 'behavior/pages/forgotPassword';
import { FormName } from 'behavior/pages';
import { useSimpleTexts } from 'components/sanaText';

const ForgotPasswordForm = ({ email, submitForgotPassword, className = '' }) => {
  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;

  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    submitForgotPassword(formData, FormName.ForgotPassword);
  }, FormName.ForgotPassword, []);

  return (
    <SanaForm
      onSubmit={onSubmit}
      name={FormName.ForgotPassword}
      initialValues={{ email: email || '' }}
      focus={email != null}
      className={`${styles.form} ${className}`}
    >
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{ required: true }}
      />
      <CaptchaFormGroup ref={captchaRef} captchaId={FormName.ForgotPassword} />
      <SanaForm.SubmitRow />
    </SanaForm>
  );
};

ForgotPasswordForm.propTypes = {
  email: PropTypes.string,
  submitForgotPassword: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default connect(null, { submitForgotPassword })(ForgotPasswordForm);
