import styles from '../ClosedStore.module.scss';
import { useSimpleTexts } from 'components/sanaText';
import { DocumentTitle, useDocumentTitle } from 'components/objects/documentTitle';
import LoginForm from 'components/objects/login/form';
import { RichText } from 'components/sanaText';
import { useVerticalOverflowFix } from 'utils/hooks';
import ClosedStoreLayout from '../ClosedStoreLayout';

export default () => {
  const [title] = useSimpleTexts(['Login_Title']).texts;
  const documentTitle = useDocumentTitle(title);
  useVerticalOverflowFix();

  return (
    <ClosedStoreLayout>
      {documentTitle && <DocumentTitle title={documentTitle} />}
      <div className={styles.headerText}>
        <RichText textKey="ClosedStore_Login_Header" />
      </div>
      <div className={styles.descriptionText}>
        <RichText textKey="ClosedStore_Login_Description" />
      </div>
      <LoginForm />
    </ClosedStoreLayout>
  );
};
