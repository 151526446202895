import PropTypes from 'prop-types';
import ForgotPasswordForm from 'components/objects/forgotPassword/form';
import { useVerticalOverflowFix } from 'utils/hooks';
import { BackTo } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { RichText } from 'components/sanaText';
import ClosedStoreLayout from '../ClosedStoreLayout';
import { PageTitle } from 'components/objects/pages';

const ClosedShopForgotPassword = ({ options }) => {
  const backTexts = ['ButtonText_BackToLogin', 'ButtonText_Login'];

  useVerticalOverflowFix();

  return (
    <ClosedStoreLayout>
      <div>
        <BackTo to={routesBuilder.forLogin} texts={backTexts} />
      </div>
      <div>
        <PageTitle textKey="ForgotPassword" asDocumentTitle />
        <RichText textKey="ForgotPassword_Intro" />
      </div>
      <ForgotPasswordForm email={options?.email} />
    </ClosedStoreLayout>
  );
};

ClosedShopForgotPassword.propTypes = {
  options: PropTypes.shape({
    email: PropTypes.string,
  }),
};

export default ClosedShopForgotPassword;
