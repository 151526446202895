// [216777] Automated Email Order Confirmation
import type { RowContentElement } from 'behavior/content';
import { memo } from 'react';
import { renderRows, extendDefaultSettings } from 'components/containers';
import { desktop as defaultDesktop } from './defaultContent';

type Props = {
    desktop: RowContentElement[] | null;
};

const DesktopTemplate = ({ desktop }: Props) => renderRows(desktop ?? extendDefaultSettings(defaultDesktop));

export default memo(DesktopTemplate);
